import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SurveysService {
  private _surveysData$ = new BehaviorSubject<any>(null);
  public readonly surveysData$ = this._surveysData$.asObservable();
  _user$: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.surveysData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/surveys/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._surveysData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getSurveys = ({
    filterString, activeFlag = 1, sortColumn = 'name', sortDirection = 'ASC', limit, offset = 0, includedAssociations = 'Courses',
  }: {
    filterString: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    includedAssociations: string;
    }) => {
    const params: {
      loginName: string;
      activeFlag: 1 | 0;
      filterString?: string;
      sortColumn?: string;
      sortDirection?: 'ASC' | 'DESC';
      limit?: number;
      offset?: number;
      includedAssociations: string;
    } = {
      loginName: this._user$.loginName,
      activeFlag,
      includedAssociations,
    }

    if (filterString) {
      params.filterString = filterString;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    params.includedAssociations = includedAssociations;

    return this.http.get(`${environment.expressUrl}/surveys`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._surveysData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })

  }

  getInactiveSurveys = ({
    filterString, activeFlag = 1, sortColumn = 'survey', sortDirection = 'ASC', limit, offset = 0, includedAssociations = 'Courses',
  }: {
    filterString: string;
    activeFlag: 1 | 0;
    sortColumn: string;
    sortDirection: 'ASC' | 'DESC';
    limit: number;
    offset: number;
    includedAssociations: string;
    }) => {
    const params: {
      loginName: string;
      activeFlag: 1 | 0;
      filterString?: string;
      sortColumn?: string;
      sortDirection?: 'ASC' | 'DESC';
      limit?: number;
      offset?: number;
      includedAssociations: string;
    } = {
      loginName: this._user$.loginName,
      activeFlag,
      includedAssociations,
    }

    if (filterString) {
      params.filterString = filterString;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    params.includedAssociations = includedAssociations;

    return this.http.get(`${environment.expressUrl}/surveys`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._surveysData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })

  }

  getSortedSurveys = (
    direction: string, showActiveItems: 1 | 0, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/surveys`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit, 'offset': offset, 'activeFlag': showActiveItems ? 1 : 0, 'sortDirection': direction,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._surveysData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  addSurvey = (survey: any) => {
    const surveyPayload = {
      loginName: this._user$.loginName,
      addedByEmail: this._user$.email,
      name: survey.name,
      url: survey.url,
      description: survey.description,
      // 'activeFlag': survey.activeFlag ? 1 : 0,
      // 'status': survey.status,
    };

    return this.http.post(
      `${environment.expressUrl}/surveys`,
      surveyPayload,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    );
  }

  updateSurvey = (survey: any) => {
    return this.http.put(
      `${environment.expressUrl}/surveys/${survey.id}`,
      {
        'loginName': this._user$.loginName,
        'name': survey.name,
        'url': survey.url,
        'description': survey.description,
        'activeFlag': survey.activeFlag ? 1 : 0,
        // 'status': survey.status,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  deleteSurvey = (survey: any) => {
    return this.http.delete(`${environment.expressUrl}/surveys/${survey.id}`, {
      body: { 'loginName': this._user$.loginName },
      headers: { 'Content-Type': 'application/vnd.api+json' },
    })
  }
}
