import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: [ './sso-callback.component.scss' ],
})
export class SsoCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['code']) {
        console.log('SSO Callback - Code detected in URL:', params['code']);
        this.authService.handleSSOCallback(params['code']);


      } else {
        console.log('No SSO code found in URL.');
        this.router.navigate([ '/signin' ]); // Redirect if no code is present
      }
    });
  }
}
