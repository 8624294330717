import {
  Component, Inject, OnInit, OnDestroy,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-registration-success-modal',
  templateUrl: './registration-success-modal.component.html',
  styleUrls: [ './registration-success-modal.component.scss' ],
})
export class RegistrationSuccessModalComponent implements OnInit {
  selectedCourse = this.data.selectedCourse;
  registrationsStatus = this.data.registrationStatus;

  messages = {
    REGISTERED: 'This course has been applied to your PD Plan.',
    WAITLISTED: 'You have been added to the waitlist for this course.',
    PENDING: 'Your registration has been applied and is pending approval.',
  }

  constructor(
    public dialogRef: MatDialogRef<RegistrationSuccessModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedCourse: any,
      registrationStatus: 'REGISTERED' | 'WAITLISTED' | 'PENDING'
    },

  ) { }

  ngOnInit(): void {
    this.selectedCourse.sessionStartDate =
      new Date(this.selectedCourse.sessionStartDate).toLocaleDateString();
  }

  closeModal = () => {
    this.dialogRef.close();
  }
}
