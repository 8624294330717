import { Component, OnInit } from '@angular/core';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { GeneralDataCell } from 'src/app/components/new-table/table-cell/table-cell.component';
import { Observable, Subscription } from 'rxjs';
import { WaitlistsService } from 'src/app/services/waitlists.service';
import { Router } from '@angular/router';
import { CatalogCourseDetailsComponent } from 'src/app/routes/manage-courses/components/course-catalog/components/catalog-course-details/catalog-course-details.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCourseComponent } from '../course-catalog/components/update-course/update-course.component';

@Component({
  selector: 'app-waitlists',
  templateUrl: './waitlists.component.html',
  styleUrls: [ './waitlists.component.scss' ],
})
export class WaitlistsComponent implements OnInit {

  tableData: NewTableData = {
    columnTitles: [
      {
        name: 'Waitlisted Course',
        sortable: true,
        sortByProp: 'courseName',
      },
      {
        name: 'Date/Time',
        sortable: true,
        isDefaultSort: true,
        sortDirection: 'ASC',
        sortByProp: 'dateOffered',
      },
      {
        name: 'Hours',
        sortable: false,
        sortByProp: 'takenCount',
      },
      {
        name: 'Waiting',
        sortable: false,
        sortByProp: 'takenCount',
      },
    ],
    selectable: false,
    data: [],
    noDataFoundMessage: 'No courses found',
    rowStyling: 'default',
    title: 'Selected Waitlisted Course',
    burgerContent: [
      {
        content: 'View Course Details',
        eventName: 'view-details',
      },
      {
        content: 'Edit Course',
        eventName: 'edit-course',
      },
      {
        content: 'Manage Waitlist',
        eventName: 'manage-waitlist',
      },
    ],
    hasBurgerMenu: true,
    meta: {
      totalItems: 0,
      itemsPerPage: 0,
      totalPages: 0,
      curPage: 0,
      usePagination: true,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
  }
  searchQuery = ''
  sortColumn = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortByProp || '';
  sortDirection = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortDirection || 'ASC';
  offset = 0;
  itemsPerPage = 25;
  waitlists: any[] = [];
  waitlist = {}
  formattedWaitlists: NewTableData['data']= [];
  waitlistsData$ = this.waitlistsService.waitlistsData$;
  waitlistsSubscription = this.waitlistsData$.subscribe((data) => {
    if (!data) {
      return;
    }

    this.waitlists = data.rows;
    this.formatWaitlists(this.waitlists);
    this.tableData.data = this.formattedWaitlists;
  });

  constructor(
    private waitlistsService: WaitlistsService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.searchForWaitlists()
  }

  handleRowClicked = (itemId: number) => {
    this.router.navigate([ `/manage-courses/waitlists/${itemId}` ])
  }

  handleBurgerMenuClicked = (event) => {
    if (event.eventName === 'view-details') {
      this.openCourseDetailsModal(event.itemId)
    }
    if (event.eventName === 'manage-waitlist') {
      this.router.navigate([ `/manage-courses/waitlists/${event.itemId}` ])
    }
    if (event.eventName === 'edit-course') {
      this.openEditCourseModal(event.itemId)
    }
  }

  openEditCourseModal = (id) => {
    const selectedCourse = this.waitlists.find((courseItem: any) => courseItem.courseItemId === id);
    const dialog = this.dialog.open(UpdateCourseComponent, { data: selectedCourse, disableClose: true })
  }

  openCourseDetailsModal = (id) => {
    this.dialog.open(CatalogCourseDetailsComponent, { data: { courseItemId: id, userRequirements: [] } })
  }

  searchForWaitlists() {
    this.waitlistsService.getWaitlists({
      limit: this.itemsPerPage,
      offset: this.offset,
      activeFlag: 1,
      filterString: this.searchQuery,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      // includedAssociations: 'Courses',
    })
  }

  formatDateOffered = (date) => {
    const dt = new Date(date)
    const month = dt.getMonth() + 1
    const day = dt.getDate()
    const year = dt.getFullYear().toString().slice(2, 4)
    return `${month}/${day}/${year}`
  }

  formatSessionTime = (startDate, endDate) => {
    const startDt = new Date(startDate)
    let hours = startDt.getHours();
    let minutes = startDt.getMinutes().toString();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes.padStart(2, '0')
    const strTime = hours + ':' + minutes + ' ' + ampm;
    const endDt = new Date(endDate)
    hours = endDt.getHours();
    minutes = String(endDt.getMinutes());
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes.padStart(2, '0')
    const endTime = hours + ':' + minutes + ' ' + ampm;
    return `${strTime} - ${endTime}`
  }

  formatWaitlists(waitlists: any[]): typeof this.formattedWaitlists {
    this.formattedWaitlists = waitlists?.map(waitlist => {
      const waitlistData = {
        name: waitlist.courseName,
        id: waitlist.courseItemId,
        dateOffered: this.formatDateOffered(waitlist.dateOffered),
        sessionTime: this.formatSessionTime(waitlist.sessionStartDate, waitlist.sessionEndDate),
        hours: waitlist.trackingValue,
        waitlistedCount: waitlist.waitlistedCount,
      }

      const nameCell: GeneralDataCell = {
        type: 'general',
        mainText: waitlistData.name,
        subText: waitlistData.id,
        mainFontStyle: 'bold',
      }

      const dateCell: GeneralDataCell = {
        type: 'general',
        mainText: waitlistData.dateOffered,
        subText: waitlistData.sessionTime,
        mainFontStyle: 'bold',
      }

      const hoursCell: GeneralDataCell = {
        type: 'general',
        mainText: `${Number(waitlistData.hours).toFixed(2)}`,
        mainFontStyle: 'normal',
      }

      const waitingCell: GeneralDataCell = {
        type: 'general',
        mainText: waitlistData.waitlistedCount,
        mainFontStyle: 'normal',
      }

      return {
        itemId: waitlist.courseItemId,
        columnData: [
          nameCell, dateCell, hoursCell, waitingCell,
        ],
      }
    });

    return this.formattedWaitlists;
  }

  searchQueryChanged = (e) => {
    this.searchQuery = e;
    this.searchForWaitlists();
  }
}
