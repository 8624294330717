enum ROLE_AUTH_LEVEL_KIDS {
  MANAGE_PRIMARY_AUTHORITY = 'MANAGE_PRIMARY_AUTHORITY',
  MANAGE_SECONDARY_AUTHORITY = 'MANAGE_SECONDARY_AUTHORITY',
  MANAGE_ALL_AUTHORITY = 'MANAGE_ALL_AUTHORITY',
  BASIC = 'BASIC',
}

type ROLE_AUTH_LEVEL_KIDS_TYPES = ROLE_AUTH_LEVEL_KIDS;

const ROLE_AUTH_LEVEL_KIDS_OPTIONS = Object.keys(ROLE_AUTH_LEVEL_KIDS) as ROLE_AUTH_LEVEL_KIDS_TYPES[];

type MAPPED_AUTH_LEVEL_PERMISSIONS_OBJECT = {
  [key in ROLE_AUTH_LEVEL_KIDS_TYPES]?: 0 | 1;
}

enum PERMISSION_KIDS {
  // ? APP CREATED
  NONE_REQUIRED = 'NONE_REQUIRED',
  FRONTEND_NOT_USED = 'FRONTEND_NOT_USED',

  // ? DB CREATED
  // ? INTERNAL - hidden unless permission granted
  INT_KALPA_MAINTENANCE = 'INT_KALPA_MAINTENANCE',
  INT_KALPA_SWITCH_USER = 'INT_KALPA_SWITCH_USER',

  // ? EXTERNAL - hidden unless permission granted
  // SWAP_PRESIDENT = 'SWAP_PRESIDENT',

  // ? EXTERNAL
  COURSE_ADD = 'COURSE_ADD',
  COURSE_EDIT = 'COURSE_EDIT',
  COURSE_PUSH = 'COURSE_PUSH',
  COURSE_REQUESTS = 'COURSE_REQUESTS',
  COURSE_SUGGESTIONS = 'COURSE_SUGGESTIONS',

  EDIT_BULLETIN_BOARD = 'EDIT_BULLETIN_BOARD',
  EXPORT_STATE_DOC = 'EXPORT_STATE_DOC',

  MANAGE_SURVEYS = 'MANAGE_SURVEYS',
  RUN_DATA_REPORTS = 'RUN_DATA_REPORTS',

  SYS_SETTINGS_ACCESS = 'SYS_SETTINGS_ACCESS',
  SYS_SETTINGS_DEFAULTS = 'SYS_SETTINGS_DEFAULTS',
  SYS_SETTINGS_BUILDINGS = 'SYS_SETTINGS_BUILDINGS',
  SYS_SETTINGS_POSITIONS = 'SYS_SETTINGS_POSITIONS',
  SYS_SETTINGS_REQUIREMENTS = 'SYS_SETTINGS_REQUIREMENTS',
  SYS_SETTINGS_TYPES = 'SYS_SETTINGS_TYPES',
  SYS_SETTINGS_CATEGORIES = 'SYS_SETTINGS_CATEGORIES',
  SYS_SETTINGS_TAGS = 'SYS_SETTINGS_TAGS',
  SYS_SETTINGS_ROLES = 'SYS_SETTINGS_ROLES',

  USER_ACCOUNTS = 'USER_ACCOUNTS',
  USER_ADD = 'USER_ADD',
  USER_EDIT = 'USER_EDIT',

  VALIDATE_ATTENDANCE = 'VALIDATE_ATTENDANCE',
}

type PERMISSION_KID_TYPES = keyof typeof PERMISSION_KIDS;
const PERMISSION_KID_OPTIONS = Object.keys(PERMISSION_KIDS) as PERMISSION_KID_TYPES[];

type MAPPED_PERMISSIONS_OBJECT = {
  [key in PERMISSION_KID_TYPES]: 0 | 1;
};

interface USER_PERMISSIONS_OBJECT extends MAPPED_PERMISSIONS_OBJECT, MAPPED_AUTH_LEVEL_PERMISSIONS_OBJECT {
}

export {
  PERMISSION_KIDS,
  USER_PERMISSIONS_OBJECT,
}
