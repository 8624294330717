import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCourseComponent } from './components/update-course/update-course.component';
import { CoursesService } from 'src/app/services/courses.service';
import { CourseFiltersComponent } from './components/course-filters/course-filters.component';
import { CatalogCourseDetailsComponent } from './components/catalog-course-details/catalog-course-details.component';
import { RegistrationRequirementModalComponent } from './components/registration-requirement-modal/registration-requirement-modal.component';
import { RegistrationWaitlistModalComponent } from './components/registration-waitlist-modal/registration-waitlist-modal.component';
import { EntityService } from 'src/app/services/entity.service';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';
import { CourseFilter } from './components/course-filters/courseFilter';
import {
  NewTableData, TableBurgerClickEvent, TablePageChangeEvent, TableRow, TableRowClickEvent,
  TableSortEvent,
} from 'src/app/components/new-table/new-table.component';
import { formatDate } from 'src/app/utils';
import { GeneralDataCell, IconCell } from 'src/app/components/new-table/table-cell/table-cell.component';
import { PERMISSION_KIDS, USER_PERMISSIONS_OBJECT } from 'src/app/constants/permissionKids';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { Subscription } from 'rxjs';
import { VerificationComponent } from 'src/app/components/verification/verification.component';

@Component({
  selector: 'app-course-catalog',
  templateUrl: './course-catalog.component.html',
  styleUrls: [ './course-catalog.component.scss' ],
})
export class CourseCatalogComponent implements OnInit, OnDestroy {
  meatballMenu: any;
  years: any;
  formattedYears: any;
  isLoading = false;
  error: any;
  rawCourseData: any;
  courseList: any;
  totalCount: any;
  currentFilters: any;
  user: any;
  permissions: any;
  mainFilter: CourseFilter;
  filterString = '';
  sortColumn = 'dateOffered';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  yearId: number;

  loadingSpinnerIsLoading = true;

  userCurrentRegistrationIds: number[];
  userRegistrations: any;
  userCanCreate = false;
  userCanEdit = false;
  limit = 25;
  offset = 0;

  selectedYear: number;

  hasPrivilege = false;
  privilegeKIDs = [
    PERMISSION_KIDS.COURSE_ADD, PERMISSION_KIDS.COURSE_EDIT, PERMISSION_KIDS.COURSE_PUSH,
  ];


  // #region Subscription Declarations
  coursesDataSubscription: Subscription;
  entitySubscription: Subscription;
  permissionSubscription: Subscription;
  userSubscription: Subscription;
  userWithRequirementsSubscription: Subscription;
  loadingSpinnerSubscription: Subscription;

  // #endregion Subscription Declarations


  // #region new Table Data
  tableData: NewTableData = {
    title: 'Course Catalog',
    columnTitles: [
      {
        name: 'Course',
        sortable: true,
        sortByProp: 'courseName',
        width: '300px',
      },
      {
        name: 'Location',
        sortable: true,
        sortByProp: 'location',
      },
      {
        name: 'Date & Time',
        sortable: true,
        sortByProp: 'dateOffered',
        isDefaultSort: true,
        sortDirection: 'ASC',
      },
      {
        name: 'Hours',
        sortable: true,
        sortByProp: 'trackingValue',
      },
      {
        name: 'Enrollment',
        sortable: true,
        sortByProp: 'registeredCount',
      },
      { name: '' },
    ],
    displayNoDataMessage: false,
    noDataFoundMessage: 'No courses match the current filters',
    rowStyling: 'default',
    burgerContent: [
      {
        content: 'View Details',
        eventName: 'viewDetails',
      },
      {
        content: 'Register',
        eventName: 'register',
        displayCondition: (itemId: number) => {
          const isCancelled = this.checkCourseCancelled(itemId);
          if (isCancelled) return false;

          const isRegistered = this.checkForRegistration(itemId);
          if (isRegistered) return false;

          const { canWaitlist, classFull } = this.confirmCourseStatus(itemId);
          return !canWaitlist && !classFull;
        },
      },
      {
        content: 'Join Waitlist',
        eventName: 'joinWaitlist',
        displayCondition: (itemId: number) => {
          const isCancelled = this.checkCourseCancelled(itemId);
          if (isCancelled) return false;

          const isRegistered = this.checkForRegistration(itemId);
          if (isRegistered) return false;

          const { canWaitlist } = this.confirmCourseStatus(itemId);

          return canWaitlist;
        },
      },
      {
        content: 'Edit Course',
        eventName: 'editCourse',
        displayCondition: () => this.confirmPermissions(),
      },
      {
        content: 'Cancel Course',
        eventName: 'cancelCourse',
        displayCondition: (itemId: number) => this.confirmPermissions() && !this.courseIsCancelled(itemId),
      },
    ],
    hasBurgerMenu: true,
    meta: {
      curPage: 1,
      itemsPerPage: 25,
      totalItems: 0,
      totalPages: 0,
      usePagination: true,
      links: {
        prev: '',
        next: '',
        first: '',
        last: '',
        self: '',
      },
    },
    data: [],
  }

  // #endregion new Table Data

  constructor(
    public dialog: MatDialog,
    private coursesService: CoursesService,
    private entityService: EntityService,
    private authService: AuthService,
    private usersService: UsersService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  //TODO: add pagination to the list of courses
  //TODO: update ui

  ngOnInit(): void {
    this.isLoading = true;
    this.mainFilter = new CourseFilter();
    this.entityService.getEntity('Years');
    this.formatFilter(this.mainFilter);

    // #region Subscription Handling
    this.coursesDataSubscription = this.coursesService.coursesData$.subscribe((data: any) => {
      if (!data) return;

      this.rawCourseData = data;
      this.loadingSpinnerService.setIsLoading(false);
      this.isLoading = false;

      this.processCourseData();
    })

    this.entitySubscription = this.entityService.entityData$.subscribe((data: any) => {
      // format years for reusable select
      if (data?.Years?.length > 0) {
        this.years = data?.Years.reverse();
        this.formattedYears = data?.Years?.map((year: any) => {
          return {
            text: year.years,
            value: year.id,
          }
        })

        this.selectedYear = this.formattedYears[0].value;

      }
    })

    this.permissionSubscription = this.authService.permissions$.subscribe((permissions: USER_PERMISSIONS_OBJECT) => {
      if (!permissions) return;
      this.permissions = permissions;

      this.hasPrivilege = this.privilegeKIDs.some((privilege) => permissions[privilege] === 1);

      this.userCanCreate = permissions.COURSE_ADD === 1;
      this.userCanEdit = permissions.COURSE_EDIT === 1;

      if (this.hasPrivilege) {
        this.mainFilter.ignoreRegistrationWindowFlag = true;
      }

      this.searchCourses();
    })

    this.userSubscription = this.authService.user$.subscribe((user: any) => {
      if (user) {
        this.user = user;
        this.searchForUserRegistrations();
      }
    })

    this.userWithRequirementsSubscription = this.usersService.selectedUserData$.subscribe((data: any) => {
      if (data) {
        this.user = data;
        this.userCurrentRegistrationIds = data?.Registrations?.map((registration: any) => registration.courseItemId);
        this.userRegistrations = data?.Registrations;
      }
    })

    this.loadingSpinnerSubscription = this.loadingSpinnerService.isLoading$.subscribe((isLoading: boolean) => {
      this.loadingSpinnerIsLoading = isLoading;
    })

    // #endregion Subscription Handling
  }

  searchForUserRegistrations = () => {
    const user = this.user;
    this.usersService.getUserById(user.id, { includedAssociations: 'Requirements, Registrations' });
  }

  processCourseData() {
    const data = this.rawCourseData;
    this.tableData.displayNoDataMessage = data?.rows?.length === 0;

    this.tableData.data = this.formatCourses(data);
    this.tableData.meta = this.formatMeta(data);
    data?.error ? this.error = data?.error : this.error = null;
  }

  searchCourses = (event?: any) => {
    // TODO: Course Catalog Filter by string endpoint really slow and needs to be optimized
    this.isLoading = true;
    this.tableData.data = [];

    this.formatFilter(this.mainFilter);

    const args = {
      includedAssociations: 'Tags, Items',
      courseNameFilter: this.filterString,
      limit: this.limit,
      offset: this.offset,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      ...this.currentFilters,
    }

    if (this.yearId) {
      args.yearId = this.yearId;
    }

    this.coursesService.getCourseSessions(args)
  }

  // #region Display Functions
  checkDisplayYearPermissions = () => {
    const permissions = this.permissions;

    const allowsPrivilege = [
      PERMISSION_KIDS.COURSE_ADD, PERMISSION_KIDS.COURSE_EDIT, PERMISSION_KIDS.COURSE_PUSH,
    ];

    const hasPrivilege = allowsPrivilege.some((privilege) => permissions[privilege] === 1);

    return hasPrivilege;
  }
  // #endregion Display Functions

  // #region Format Courses
  generateLocationCell(course: any): GeneralDataCell {
    const isVirtual = course.virtualFlag;

    if (isVirtual) {
      return {
        type: 'general',
        mainText: 'Virtual',
        subText: '-',
      }
    }

    return {
      type: 'general',
      mainText: course.location || '-',
      subText: course.room || '-',
    }
  }

  generateDateCell(course: any): GeneralDataCell {
    const type = course?.preRecordedOption;
    const isAsync = type === 'ASYNC';
    const isAnytime = type === 'ANYTIME';

    if (isAsync) {
      return {
        type: 'general',
        mainText: 'Async',
        subText: 'Async',
      }
    }

    if (isAnytime) {
      return {
        type: 'general',
        mainText: formatDate.asDate(course?.dateOffered) || '-',
        subText: 'Anytime',
      }
    }

    const dateCell: GeneralDataCell = {
      type: 'general',
      mainText: formatDate.asDate(course.dateOffered) || '-',
      subText: `${formatDate.asTime(course.sessionStartDate)} - ${formatDate.asTime(course.sessionEndDate)}`,
    };

    return dateCell;
  }

  formatSingleCourse = (course: any) => {
    const courseCell: GeneralDataCell = {
      type: 'general',
      mainText: course.courseName || '-',
      subText: course.courseItemId || '-',
    };
    const locationCell = this.generateLocationCell(course);

    const dateCell = this.generateDateCell(course);
    const hoursCell: GeneralDataCell = {
      type: 'general',
      mainText: course.trackingValue || '0',
    };

    const enrollmentCount = course.registeredCount || '0';
    const maxEnrollment = course.courseSize || '0';

    const enrollmentCell: GeneralDataCell = {
      type: 'general',
      mainText: `${enrollmentCount} / ${maxEnrollment}`,
      mainFontStyle: 'normal',
    };


    const { canWaitlist, classFull } = this.confirmCourseStatus(course.courseItemId);
    const iconCell: IconCell = {
      type: 'icon',
      icon: 'timelapse',
      display: canWaitlist && classFull,
      helperText: 'Waitlist Open',
      color: '#C2731C',
    }

    const { rowColor, rowStatus } = this.decideRowColor(course);

    const fullRowData: TableRow = {
      itemId: course.courseItemId,
      rowColor: rowColor,
      rowTooltip: rowStatus,
      columnData: [
        courseCell,
        locationCell,
        dateCell,
        hoursCell,
        enrollmentCell,
        iconCell,
      ],
    }

    return fullRowData;
  }

  decideRowColor = (course: any): {
    rowColor: TableRow['rowColor'],
    rowStatus: string;
} => {
    const status = {
      rowStatus: '',
      rowColor: '',
    }

    if (course.cancelFlag == 1) {
      return {
        rowColor: 'danger',
        rowStatus: 'Course Cancelled',
      }
    }

    if (course.activeFlag == 0) {
      return {
        rowColor: 'inactive',
        rowStatus: 'Course Inactive',
      }
    }

    const registration = this.userRegistrations?.find((registration: any) => {
      return registration.courseItemId == course.courseItemId;
    });

    if (registration) {
      const status = registration.currentStatusKid;

      const capitalCaseStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

      return {
        rowColor: 'success',
        rowStatus: capitalCaseStatus,
      }
    }

    return {
      rowColor: 'default',
      rowStatus: '',
    }
  }

  formatCourses = (courses: any) => {
    this.totalCount = courses?.pagination?.totalItems;
    this.courseList = courses?.rows;

    const formattedCourses = courses?.rows?.map((course: any) =>
      this.formatSingleCourse(course));

    return formattedCourses;
  }

  formatMeta = (courses: any): NewTableData['meta'] => {
    const { pagination } = courses;

    return {
      usePagination: true,

      curPage: pagination.curPage,
      itemsPerPage: pagination.totalPerPage,
      totalItems: pagination.totalItems,
      totalPages: pagination.totalPages,
      links: courses.links,
    }
  };

  // #endregion Format Courses

  // #region Table Event Handling
  onRowClicked(itemId: TableRowClickEvent) {
    const selectedCourse = this.courseList.find((course: any) => course.courseItemId == itemId);

    this.openCourseDetailsModal(selectedCourse);
  }

  onSortClicked(event: TableSortEvent) {
    const { columnName, sortDirection } = event;
    this.sortColumn = columnName;
    this.sortDirection = sortDirection;
    this.searchCourses();
  }

  onPageChanged(event: TablePageChangeEvent) {
    const offset = (event - 1) * this.limit;
    this.offset = offset;
    this.searchCourses();
  }

  onItemsPerPageChange(event: number) {
    this.limit = event;
    this.offset = 0;
    this.searchCourses();
  }

  onBurgerMenuClicked(event: TableBurgerClickEvent) {
    const { eventName, itemId } = event;
    const selectedCourse = this.courseList.find((course: any) => course.courseItemId === itemId);

    if (eventName === 'register' || eventName === 'joinWaitlist') {
      this.openRegistrationModal(selectedCourse);
    }

    if (eventName === 'viewDetails') {
      this.openCourseDetailsModal(selectedCourse);
    }

    if (eventName === 'editCourse') {
      this.openUpdateModal(selectedCourse);
    }

    if (eventName === 'cancelCourse') {
      this.cancelCourse(selectedCourse.courseItemId);
    }


  }

  // #endregion Table Event Handling

  // #region Table Meatball Menu Handling
  getCourse = (courseItemId: number) => {
    return this.courseList.find((courseItem: any) => courseItem.courseItemId === courseItemId);
  }

  checkCourseCancelled = (courseItemId: number) => {
    const course = this.courseList.find((courseItem: any) => {
      return courseItem.courseItemId === courseItemId;
    });

    return course.cancelFlag == 1;
  }

  checkForRegistration = (courseItemId: number) => {
    const isRegistered = this.userCurrentRegistrationIds.includes(courseItemId);

    return  isRegistered;
  }

  confirmCourseStatus = (courseItemId: number) => {
    const course = this.courseList.find((courseItem: any) => {
      return courseItem.courseItemId === courseItemId;
    });

    const {
      registeredCount, courseSize, allowWaitListFlag,
    } = course;

    const cancelled = course.cancelFlag == 1;


    return {
      canWaitlist: (allowWaitListFlag == 1 && registeredCount >= courseSize) && !cancelled,
      classFull: registeredCount >= courseSize,
    };

  }

  courseIsCancelled = (courseItemId: number) => {
    const course = this.courseList.find((courseItem: any) => {
      return courseItem.courseItemId === courseItemId;
    });

    return course.cancelFlag == 1;
  }

  confirmPermissions = () => {
    return this.userCanEdit || this.userCanCreate;
  }

  cancelCourse = (courseItemId: number) => {
    const confirmDialogRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert',
        title: 'Are you sure you want to cancel this session?',
        text: 'Users will be unregistered and the session will be removed. This action cannot be undone.',
        confirmButtonTitle: 'Yes, cancel course',
        cancelButtonTitle: 'No, go back',
      },
    })
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'verified') {
        const sub = this.coursesService.cancelCourseSession(courseItemId, confirmDialogRef);

        sub.add(() => {
          this.searchCourses();
        })
      }
    })
  }
  // #endregion Table Meatball Menu Handling

  // #region Filter Functions
  updateYearFilter = (yearId: any) => {
    this.formatFilter(this.mainFilter);
    this.yearId = yearId;
    this.searchCourses();
  }

  setMeatball = (event: Event, meatballMenu: any) => {
    event.stopPropagation();
    if(this.meatballMenu === meatballMenu) {
      this.meatballMenu = '';
      return;
    }
    this.meatballMenu = meatballMenu;
  }


  formatFilter = (filter: CourseFilter) => {
    const requirementIds = filter.requirements?.map((requirement: any) => { return parseFloat(requirement.id) });
    const requirementIdString = requirementIds?.join(', ');

    const tagIds = filter.tags?.map((tag: any) => { return parseFloat(tag.id) });
    const tagIdString = tagIds?.join(', ');

    const categoryIds = filter.categories?.map((category: any) => { return parseFloat(category.id) });
    const categoryIdString = categoryIds?.join(', ');

    const courseTypeIds = filter.courseTypes?.map((courseType: any) => { return parseFloat(courseType.id) });
    const courseTypeIdString = courseTypeIds?.join(', ');

    const buildingIds = filter.buildings?.map((building: any) => { return parseFloat(building.id) });
    const buildingIdString = buildingIds?.join(', ');


    // TODO: need to add the time range filter, once they are available in the api
    const formattedFilters: any = {
      includedAssociations:'Tags, Items',
      showCancelled: filter.showCancelledCoursesFlag ? 1 : 0,
      showAsync: filter.showAsyncFlag ? 1 : 0,
      showAnytime: filter.showAnytimeCoursesFlag ? 1 : 0,
      showVirtual: filter.showVirtualFlag ? 1 : 0,
      showOutsideCourse: filter.showOutsideCoursesFlag ? 1 : 0,
      showWaitlisted: filter.showWaitlistedCoursesFlag ? 1 : 0,
      ignoreRegistrationWindow: filter.ignoreRegistrationWindowFlag ? 1 : 0,
      specialRequestFlag: filter.specialRequestFlag ? 1 : 0,
      courseNameFilter: filter.courseNameFilter || null,
      targetValueMin: filter.hours,

      requirementIds: requirementIdString || null,
      tagIds: tagIdString || null,
      categoryIds: categoryIdString || null,
      buildingIds: buildingIdString || null,
      courseTypeIds: courseTypeIdString || null,

      startDateRange: filter?.startDate ? filter?.startDate?.toISOString() : null,
      endDateRange: filter?.endDate ? filter?.endDate?.toISOString() : null,
    };
    // this removes null and empty string values from formatted filters
    Object.keys(formattedFilters).forEach((key) => (formattedFilters[key] === null || formattedFilters[key] === '') && delete formattedFilters[key]);

    this.currentFilters = formattedFilters;

    return formattedFilters
  }

  searchCoursesByString = (name: string) => {
    this.filterString = name;
    this.searchCourses();
  }

  // #endregion Filter Functions

  // #region Modal Handling
  popAddNewModal = () => {
    const dialog = this.dialog.open(UpdateCourseComponent, { disableClose: true })

    dialog.afterClosed().subscribe(() => {
      this.isLoading = true;
      this.formatFilter(this.mainFilter);
      this.searchCourses();
    });
  }

  openUpdateModal = (selectedCourse: any) => {
    const dialog = this.dialog.open(UpdateCourseComponent, { data: selectedCourse, disableClose: true })

    dialog.afterClosed().subscribe(result => {
      if (result?.statusCode === 1000) {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.searchCourses();
        this.userCurrentRegistrationIds.push(result.courseItemId);
      }
      this.searchForUserRegistrations();
    });
  }

  openFiltersModal = () => {
    const dialogRef = this.dialog.open(CourseFiltersComponent, { data: this.mainFilter })
    dialogRef.afterClosed().subscribe(result => {
      if(result && !result.noChange) {
        this.isLoading = true;

        // TODO: need to add the time range filter, hours filter, buildings filter, categories filter, and course types filter once they are available in the api
        this.formatFilter(result.filter);

        this.offset = 0;

        this.searchCourses();
      } else if (result && result.noChange) {
        this.isLoading = false;
      }
    })
  }

  openCourseDetailsModal = (selectedCourse: any) => {
    this.meatballMenu = '';
    const dialogRef = this.dialog.open(CatalogCourseDetailsComponent, { data: { selectedCourse, userRequirements: this.user.Requirements } })


    dialogRef.afterClosed().subscribe(result => {
      if (result === 'reload' || result?.statusCode === 1000) {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.searchCourses();
      }
      this.searchForUserRegistrations();
    })
  }

  openRegistrationModal = (course: any) => {
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialog = this.dialog.open(RegistrationRequirementModalComponent, { data: { selectedCourse, userRequirements: this.user.Requirements } })

    dialog.afterClosed().subscribe(result => {
      if (result === 'reload') {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
      }
      this.searchForUserRegistrations();
      this.searchCourses();
    });
  }

  openWaitlistModal = (event: Event, course: any) => {
    event.stopPropagation();
    this.meatballMenu = '';
    const selectedCourse = this.courseList.find((courseItem: any) => courseItem.courseItemId === course.courseItemId);
    const dialog = this.dialog.open(RegistrationWaitlistModalComponent, { data: { selectedCourse } })

    dialog.afterClosed().subscribe(result => {
      if(result === 'reload') {
        this.isLoading = true;
        this.formatFilter(this.mainFilter);
        this.searchCourses();
      }
    });
  }

  // #endregion Modal Handling

  ngOnDestroy(): void {
    this?.coursesDataSubscription?.unsubscribe();
    this?.entitySubscription?.unsubscribe();
    this?.userSubscription?.unsubscribe();
    this?.userWithRequirementsSubscription?.unsubscribe();
    this?.permissionSubscription?.unsubscribe();
  }
}
