<div class="container">
  <app-courses-list
    *ngIf="activeTab === 'courses'"
    [user]="user"
  ></app-courses-list>

  <app-requirements-list
    *ngIf="activeTab === 'requirements'"
    [user]="user"
  ></app-requirements-list>
</div>
