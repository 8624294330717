<div class="container">
  <div class="fixed-area">
    <div class="title-row">
      <span class="klabel-huge">Filter by Building</span>
      <app-button
        [text]="'De-select all'"
        class="grey"
        (click)="clearBuildingsFilter()"
      ></app-button>
    </div>
  </div>
  <div class="content">
    <div class="checkboxes">
      <div *ngIf="buildingsList.length !== 0" class="checkbox-row">
        <input
          type="checkbox"
          class="kinput"
          [checked]="
            buildingsList.length === mainFilter.buildings.length &&
            buildingsList.length !== 0
          "
          (change)="selectAllBuildingsFilter($event)"
        />
        <span class="kbody-medium">Select all buildings</span>
      </div>
      <div class="checkbox-row" *ngFor="let building of buildingsList">
        <input
          type="checkbox"
          class="kinput"
          [checked]="checkIncludesBuilding(building)"
          (change)="updateCheckboxFilter($event, building)"
        />
        <span class="kbody-medium">{{ building?.buildingName }}</span>
      </div>
    </div>
  </div>
</div>
