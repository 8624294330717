<div *ngIf="cellIsUserInfo(cell)" class="user-info">
  <p>{{ cell.fullName }}</p>
  not implemented
</div>

<div *ngIf="cellIsGeneralInfo(cell)" class="general">
  <p
    [ngClass]="{
      'main-text': true,
      'only-main': !cell.subText
    }"
    [style]="{
      'font-weight': cell.mainFontStyle || 'bold'
    }"
  >
    {{ cell.mainText }}
  </p>
  <p class="sub-text" *ngIf="cell.subText">{{ cell.subText }}</p>
</div>

<div
  *ngIf="cellIsStatusInfo(cell)"
  [ngClass]="['status', cell.statusType, cell.statusKid]"
>
  <p>
    {{
      cell.forceUppercase
        ? cell.statusDisplay.toLocaleUpperCase()
        : cell.statusDisplay
    }}
  </p>
</div>

<div *ngIf="cellIsSpecialInfo(cell)" class="special">
  <p>
    {{ cell.specialType }}
  </p>
  not implemented
</div>

<div *ngIf="cellIsHyperlink(cell)" class="hyperlink">
  <span>{{ cell.url }}</span>
</div>

<div *ngIf="cellIsIcon(cell)" class="unknown">
  <mat-icon
    *ngIf="cell.display"
    [ngStyle]="{ color: cell.color }"
    aria-label="icon"
    [title]="cell.helperText || ''"
    >{{ cell.icon }}</mat-icon
  >
</div>

<ng-template #unknown>Unknown data type</ng-template>

<ng-container *ngIf="isAvatarCell(cell)">
  <div class="avatar-cell">
    <app-avatar
      [size]="cell.size || 'md'"
      [firstName]="cell.firstName"
      [lastName]="cell.lastName"
    >
    </app-avatar>

    <div class="avatar-cell-text">
      <div class="full-name">{{ cell.firstName }} {{ cell.lastName }}</div>
      <div class="email">{{ cell.email }}</div>
    </div>
  </div>
</ng-container>
