<app-content-container title="Waitlist">
  <div class="top-section">
    <app-new-button
      id="back-button"
      (clickHandler)="goBack()"
      leftIcon="arrow_back"
      size="sm"
      color="bare"
      customPadding="12px 16px"
      ></app-new-button
    >
    <div class="field-container">
      <span class="label">Course ID</span>
      <span class="value">{{waitlist?.Course?.id}}</span>
    </div>
    <div class="field-container">
      <span class="label">Waitlisted Course</span>
      <span class="value">{{waitlist?.Course?.courseName}}</span>
    </div>
    <div class="field-container">
      <span class="label">Course Size</span>
      <span class="value">{{waitlist?.courseSize}}</span>
    </div>
    <div class="field-container">
      <span class="label">Date & Time</span>
      <span class="value">{{getDateAndTime()}}</span>
    </div>
  </div>
  <div class="bottom-section">
    <div *ngIf="!isLoading" class="left-side">
      <div class="users-list-title">
        <span>Users Waiting ({{waitlist?.WaitlistOrder?.length}})</span>
      </div>
      <div class="users-list">
        <div (click)="selectUser(user)" [ngClass]="{'selected': selectedUser === user}" class="user" *ngFor="let user of waitlist?.WaitlistOrder">
          <div class="user-info-container">
            <div class="user-name">{{user?.firstName}} {{user?.lastName}}</div>
            <div class="user-email">{{user?.contactEmail}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="right-side">
      <div class="actions-title">
        <span>Actions</span>
      </div>
      <div class="selected-user-details">
        <div [style.border-left]="'none'" class="field-container">
          <span class="label">User Selected</span>
          <span class="value">{{selectedUser?.firstName}} {{selectedUser?.lastName}}</span>
        </div>
        <div class="field-container">
          <span class="label">Conflicts</span>
          <span class="value">{{selectedUser?.totalOverlaps}}</span>
        </div>
        <div class="field-container">
          <span class="label">Queue</span>
          <span class="value">{{selectedUser?.waitlistOrder}}</span>
        </div>
        <app-new-button
          (clickHandler)="moveToTop()"
          leftIcon=""
          size="sm"
          color="bare"
          customPadding="12px 16px"
          >Move to Top</app-new-button
        >
        <app-new-button
          (clickHandler)="cancelWaitlistRequest()"
          leftIcon=""
          size="sm"
          color="red"
          customPadding="12px 16px"
          >Cancel Waitlist Request</app-new-button
        >
      </div>
      <div *ngIf="conflicts?.length > 0" class="conflicts-title-section">
        <span class="conflicts-title">Courses in PD Plan that conflict with the waitlisted course:</span>
      </div>
      <app-new-table
        *ngIf="conflicts?.length > 0"
        (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
        [tableData]="tableData"
      />
    </div>
  </div>
  <app-loading-spinner
    *ngIf="isLoading"
    class="loading-spinner"
  ></app-loading-spinner>
</app-content-container>
