import { Component, Input } from '@angular/core';
import { AvatarDataCell } from '../new-table.component';

export interface UserDataCell {
  type: 'user';
  avatar?: Component;
  fullName: string;
  email?: string;
  icon?: string;
}

export interface GeneralDataCell {
  type: 'general';
  icon?: string;
  mainText: string | number;
  subText?: string | number;
  badgeCount?: number;
  mainFontStyle?: 'normal' | 'bold';
}

export interface StatusDataCell {
  type: 'status';
  statusDisplay: string;
  statusKid: string;
  statusType: 'courseRegistration' | 'courseCatalog' | 'active';
  forceUppercase?: boolean;
}

export interface SpecialDataCell {
  specialType: 'checkbox' | 'icon' | 'number' | 'validation',
  actionName?: string;
}

export interface IconCell {
  type: 'icon';
  icon: string;
  display: boolean;
  helperText?: string;
  color?: string;
}

export interface HyperlinkCell {
  url: string;
}

export type TableCellTypes = UserDataCell
  | GeneralDataCell
  | StatusDataCell
  | SpecialDataCell
  | HyperlinkCell
  | IconCell
  | AvatarDataCell;

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: [ './table-cell.component.scss' ],
})
export class TableCellComponent {
  @Input() cell: TableCellTypes;

  cellIsUserInfo(cell: unknown): cell is UserDataCell {
    return !!(cell as UserDataCell).fullName;
  }

  cellIsGeneralInfo(cell: unknown): cell is GeneralDataCell {
    return !!(cell as GeneralDataCell).mainText;
  }

  cellIsStatusInfo(cell: unknown): cell is StatusDataCell {
    return !!(cell as StatusDataCell).statusDisplay;
  }

  cellIsSpecialInfo(cell: unknown): cell is SpecialDataCell {
    return !!(cell as SpecialDataCell).specialType;
  }

  cellIsHyperlink(cell: unknown): cell is HyperlinkCell {
    return !!(cell as HyperlinkCell).url;
  }

  cellIsIcon(cell: unknown): cell is IconCell {
    return !!(cell as IconCell).icon;
  }

  isAvatarCell(cell: any): cell is AvatarDataCell {
    return cell?.cellType === 'avatarCell';
  }

}
