<div class="modal" [ngClass]="{ 'allow-edit': isEditMode }">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round">post_add</mat-icon>
      <span class="kbody-huge"
        >{{
          data?.isCourseRequestApproval
            ? "Approve Course Request"
            : data
            ? "Edit"
            : "Add New"
        }}
        Course</span
      >
    </div>
    <mat-icon
      class="close-icon"
      (click)="closeDialog()"
      fontSet="material-icons-round"
      >close</mat-icon
    >
  </div>
  <div
    class="content"
    [ngClass]="{ 'edit-mode': isEditMode }"
    *ngIf="!isLoading"
  >
    <div class="edit-content">
      <app-stepper
        *ngIf="!isEditMode"
        [steps]="steps"
        [selectedStep]="selectedStep"
        (selectedStepChange)="this.selectedStep = $event"
      ></app-stepper>
      <app-general-info
        [isCourseRequestApproval]="isCourseRequestApproval"
        [isEditMode]="isEditMode"
        (closeDialogEmitter)="closeDialog()"
        [courseTypes]="courseTypes"
        [course]="updatedCourse"
        (stepForward)="stepForward($event)"
        *ngIf="
          !isLoading && (isEditMode || selectedStep.text === 'General Info')
        "
      ></app-general-info>
      <app-course-details
        [isCourseRequestApproval]="isCourseRequestApproval"
        [isEditMode]="isEditMode"
        (closeDialogEmitter)="closeDialog()"
        [course]="updatedCourse"
        (stepForward)="stepForward($event)"
        (stepBack)="stepBack()"
        *ngIf="
          !isLoading && (isEditMode || selectedStep.text === 'Course Details')
        "
      ></app-course-details>
      <app-course-sorting
        [isEditMode]="isEditMode"
        (closeDialogEmitter)="closeDialog()"
        [requirements]="requirements"
        [tags]="tags"
        [categories]="categories"
        [course]="updatedCourse"
        (stepForward)="stepForward($event)"
        (stepBack)="stepBack()"
        *ngIf="
          !isLoading && (isEditMode || selectedStep.text === 'Course Sorting')
        "
      ></app-course-sorting>
      <app-additional-details
        [isEditMode]="isEditMode"
        (closeDialogEmitter)="closeDialog()"
        [course]="updatedCourse"
        (stepBack)="stepBack()"
        (stepForward)="stepForward($event)"
        *ngIf="
          !isLoading &&
          (isEditMode || selectedStep.text === 'Additional Details')
        "
      ></app-additional-details>
      <!-- <app-attachments
        [isEditMode]="isEditMode"
        (closeDialogEmitter)="closeDialog()"
        [course]="masterUpdatedCourse"
        (updateCourseObject)="updateCourse($event)"
        (stepBack)="stepBack()"
        *ngIf="!isLoading && (isEditMode || selectedStep.text === 'Attachments')"
      ></app-attachments> -->
      <app-finish
        (closeDialogEmitter)="closeDialog()"
        [course]="updatedCourse"
        (stepBack)="stepBack()"
        *ngIf="
          !isLoading && !isEditMode && selectedStep.text === 'Review & finish'
        "
      ></app-finish>
      <app-loading-spinner
        class="spinner"
        *ngIf="this.isLoading"
      ></app-loading-spinner>
      <span *ngIf="error" class="klabel-large error">{{ error }}</span>
    </div>
    <div class="edit-buttons" *ngIf="isEditMode && !isLoading">
      <app-button
        [text]="'Cancel'"
        [class]="'white'"
        (click)="closeDialog()"
      ></app-button>
      <app-button
        [text]="isCourseRequestApproval ? 'Approve Request' : 'Save Changes'"
        [icon]="{ materialIcon: 'done' }"
        (click)="saveChanges()"
      ></app-button>
    </div>
  </div>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
</div>
