import {
  Component, Input, Output, EventEmitter, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { CoursesService } from 'src/app/services/courses.service';
import { Subscription } from 'rxjs';
import { Error5401Component } from './error-5401/error-5401.component';
import { GeneralDataCell } from 'src/app/components/new-table/table-cell/table-cell.component';

@Component({
  selector: 'app-finish-export',
  templateUrl: './finish-export.component.html',
  styleUrls: [ './finish-export.component.scss' ],
})
export class FinishAndExportComponent implements OnInit, OnDestroy {
  @Input() isHeader?= false;
  @Input() requirements = [];
  @Input() courses = [];
  @Input() settings: any;
  @Output() changeStepEvent = new EventEmitter();
  @Output() showExportSettingsEvent = new EventEmitter();
  exportSubscription: Subscription;
  includePreviouslyExportedData = false;
  excludeUsersWithoutPIC = false;

  tableData: NewTableData = {
    columnTitles: [
      {
        name: 'Course',
        sortable: false,
        sortByProp: 'courseName',
        selectableSummary: true,
      },
      {
        name: 'Date/Time',
        sortable: false,
        isDefaultSort: true,
        sortDirection: 'ASC',
        sortByProp: 'dateOffered',
      },
      {
        name: 'Exported/Taken',
        sortable: false,
        sortByProp: 'takenCount',
      },
    ],
    selectable: false,
    data: [],
    noDataFoundMessage: 'No courses found',
    rowStyling: 'default',
    title: 'Courses',
    burgerContent: [
      {
        content: 'View Course Details',
        eventName: 'view-details',
      },
      {
        content: 'View Course Validation',
        eventName: 'view-validation',
      },
    ],
    hasBurgerMenu: false,
    meta: {
      totalItems: 0,
      itemsPerPage: 0,
      totalPages: 0,
      curPage: 0,
      usePagination: true,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
  }
  searchText = '';
  sortColumn = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortByProp || '';
  sortDirection = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortDirection || 'ASC';
  formattedCourses: NewTableData['data']= [];

  constructor(public dialog: MatDialog, private courseService: CoursesService) { }

  ngOnInit() {
    this.formatCourses(this.courses);
    this.tableData.data = this.formattedCourses;
  }

  formatCourses(courses: any[]): typeof this.formattedCourses {
    this.formattedCourses = courses.map(course => {
      const courseData = {
        courseName: course.courseName,
        courseItemId: course.courseItemId,

        dateOffered: new Date(course.dateOffered),
        sessionStartDate: new Date(course.CourseItem.sessionStartDate),
        sessionEndDate: new Date(course.CourseItem.sessionEndDate),

        status: course.status,
        exportedCount: course.stateDocExportedCount,
        takenCount: course.takenCount,
      }

      const courseCell: GeneralDataCell = {
        type: 'general',
        mainText: courseData.courseName,
        subText: courseData.courseItemId.toString(),
      }

      const dateCell: GeneralDataCell = {
        type: 'general',
        mainText: courseData.dateOffered.toLocaleDateString(),
        subText: `${courseData.sessionStartDate.toLocaleTimeString('en',
          {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })} - ${courseData.sessionEndDate.toLocaleTimeString('en',
          {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })}`,
      };

      const countCell: GeneralDataCell = {
        type: 'general',
        mainText: `${courseData.exportedCount} / ${courseData.takenCount}`,
        mainFontStyle: 'normal',
      };

      return {
        itemId: course.courseItemId,
        columnData: [
          courseCell, dateCell, countCell,
        ],
      }
    });

    return this.formattedCourses;
  }

  showExportSettings = () => {
    this.showExportSettingsEvent.emit()
  }

  finishAndExport = () => {
    const exportRequestPayload = {
      courseItemIds: this.courses.map(course => Number(course.courseItemId)),
      requirementIds: this.requirements.map(requirement => Number(requirement.id)),
      excludeUsersWithoutStateIssuedId: this.settings.excludeUsersWithoutPIC,
      includePreviouslyExported: this.settings.includePreviouslyExportedUserData,
      excludedUsers: this.settings.excludedUsers.map(user => Number(user.id)),
      excludedPositions: this.settings.excludedPositions.map(position => Number(position.id)),
    }
    this.exportSubscription = (this.courseService.generateCourseReportCSV(exportRequestPayload)).subscribe({
      next: (response: any) => {
        this.downloadCSV(response.data)
      },
      error: (error: any) => {
        console.error(error)
        if (error.error.statusCode === 5401) {
          this.showError5401Modal()
        }
      },
    })
  }

  downloadCSV(data: any) {
    const blob = new Blob([ data.csvExport ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = data.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  showError5401Modal = () => {
    const dialog = this.dialog.open(Error5401Component, { disableClose: false })

    dialog.afterClosed().subscribe(() => {
      console.log('closed error 5401 component')
    });
  }

  previousStep = () => {
    this.changeStepEvent.emit(1);
  }

  ngOnDestroy() {
    this.exportSubscription?.unsubscribe();
  }
}
