<div class="requirements-view">
  <div class="requirements-list-section">
    <div class="requirements-list-title">
      <span>Requirements</span>
    </div>
    <div class="requirements-list">
      <div *ngFor="let requirement of requirements" [ngClass]="{'selected': selectedRequirement === requirement}" (click)="selectRequirement(requirement)" class="requirement">
        <span class="requirement-name">
          {{requirement.requirement || '--'}}
        </span>
      </div>
    </div>
  </div>
  <div class="selected-requirement-section">
    <div class="selected-requirement-section-title">
      <span>Requirement Details & Courses</span>
    </div>
    <div class="selected-requirement-details">
      <div class="selected-requirement-name">
        <span>{{selectedRequirement.requirement}}</span>
      </div>
      <div class="selected-requirement-stats-container">
        <div *ngIf="selectedRequirement.nodeType === 'GROUP_PARENT'" class="selected-requirement-sub-requirements-count-container">
          <span class="selected-requirement-sub-requirements-count-label">
            Sub-Requirements
          </span>
          <span class="selected-requirement-sub-requirements-count-value">
            {{subrequirements.length}}
          </span>
        </div>
        <div class="selected-requirement-type-container">
          <span class="selected-requirement-type-label">
            Requirement Type
          </span>
          <span class="selected-requirement-type-value">
            {{getSelectedRequirementType()}}
          </span>
        </div>
      </div>
    </div>
    <div class="selected-requirement-progress-container">
      <div [style.width]="getCompletedAmountWidth()" class="completed-amount"></div>
      <div [style.width]="getQueuedAmountWidth()" class="queued-amount"></div>
      <div class="queued-amount"></div>
      <div class="completed-out-of-required-stat">
        <span>{{getCompletedOutOfRequiredStat()}}</span>
      </div>
    </div>
    <div *ngIf="selectedRequirement.nodeType === 'GROUP_PARENT'" class="sub-requirements-list-container" [ngClass]="{'collapsed': !showSubRequirements}">
      <div class="sub-requirements-list-title" (click)="showSubRequirements = !showSubRequirements">
        <mat-icon class="icon material-icons-outlined expand-collapse">{{showSubRequirements ? 'expand_less' : 'expand_more'}}</mat-icon>
        <span>Sub-Requirements</span>
      </div>
      <div class="sub-requirements-list">
        <div class="sub-reqs-headers">
          <div [style.width.%]="55" class="sub-reqs-header">
            <span>Sub-Requirement</span>
          </div>
          <div [style.width.%]="15" class="sub-reqs-header">
            <span>Required</span>
          </div>
          <div [style.width.%]="15" class="sub-reqs-header">
            <span>Registered</span>
          </div>
          <div [style.width.%]="15" class="sub-reqs-header">
            <span>Completed</span>
          </div>
        </div>
        <div *ngFor="let requirement of subrequirements" class="sub-requirement">
          <div [style.width.%]="55" class="sub-req-value">
            <span class="sub-requirement-name">
              {{requirement.requirement || '--'}}
            </span>
            <span *ngIf="requirement.parent.length > 0" class="sub-requirement-parent-name">
              {{requirement.parent || '--'}}
            </span>
          </div>
          <div [style.width.%]="15" class="sub-req-value">
            <span>
              {{requirement.requiredValue | number : '1.2-2'}}
            </span>
          </div>
          <div [style.width.%]="15" class="sub-req-value">
            <span>
              {{requirement.totalRegistered | number : '1.2-2'}}
            </span>
          </div>
          <div [style.width.%]="15" class="sub-req-value">
            <span>
              {{requirement.totalCompleted | number : '1.2-2'}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-new-table
      style="margin-top: '48px'"
      [isLoading]="false"
      [tableData]="tableData"
      (rowClickedEvent)="handleRowClicked($event)"
      (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
      (sortClickedEvent)="handleSortClicked($event)"
      (noDataButtonClickedEvent)="handleNoDataButtonClicked()"
      (itemsPerPageChange)="onItemsPerPageChange($event)"
      (pageChanged)="onPageChanged($event)"
    ></app-new-table>
  </div>
</div>
