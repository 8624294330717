<table class="table" (click)="closeSummaryPanel()">
  <thead>
    <tr>
      <th
        class="selectable-column"
        *ngIf="tableData.selectable"
        (click)="selectAllDisplayed($event)"
      >
        <mat-checkbox
          [checked]="allSelectedStatus === true"
          [indeterminate]="allSelectedStatus === 'indeterminate'"
          color="#005965"
        ></mat-checkbox>
      </th>
      <th
        *ngFor="let column of tableData.columnTitles"
        [ngClass]="{
          sortable: column.sortable,
          'sort-asc': column.sortable && column.sortDirection === 'ASC',
          'sort-desc': column.sortable && column.sortDirection === 'DESC'
        }"
        [ngStyle]="{
          width: column.width !== null ? column.width : '100%'
        }"
        (click)="sortClicked(column)"
      >
        <span class="header-container">
          {{ column.name }}
          <mat-icon
            class="arrow"
            *ngIf="column.sortable"
            [ngClass]="{
              active: column.sortDirection !== null,
              asc: column.sortDirection === 'ASC',
              desc: column.sortDirection === 'DESC'
            }"
            >arrow_upward</mat-icon
          >
        </span>
      </th>
      <th class="burger-col" *ngIf="tableData.hasBurgerMenu"></th>
    </tr>
  </thead>
  <tbody
    *ngIf="tableData.data && tableData.data.length > 0"
    [ngClass]="{ loading: isLoading }"
    [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
  >
    <tr
      *ngFor="let row of tableData.data"
      [ngClass]="row.rowColor || 'default'"
      (click)="rowClicked($event, row.itemId)"
      title="{{ row.rowTooltip }}"
    >
      <td
        *ngIf="tableData.selectable"
        class="selectable-column"
        (click)="toggleSelectCell($event, row.itemId)"
      >
        <mat-checkbox
          class="stop-bubble"
          [checked]="
            checkIfSelected(row.itemId) && checkIfPanelSelected(row.itemId)
          "
          [disableRipple]="true"
          color="#005965"
        ></mat-checkbox>
      </td>
      <td
        *ngFor="let cell of row.columnData; let i = index"
        [ngStyle]="{
          width:
            tableData.columnTitles[i].width !== null
              ? tableData.columnTitles[i].width
              : 'auto'
        }"
      >
        <app-table-cell [cell]="cell" />
      </td>
      <td
        *ngIf="tableData.hasBurgerMenu && tableData.burgerContent"
        class="burger-cell"
        [ngStyle]="{
          minWidth: tableData.hamburgerMenuWidth || 'auto'
        }"
      >
        <div>
          <div
            class="burger-trigger"
            (click)="$event.stopPropagation()"
            (mousedown)="$event.stopPropagation()"
            [ngClass]="row.rowColor || 'default'"
          >
            <mat-icon mat-button [matMenuTriggerFor]="menu">
              more_horizontal
            </mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <div class="links-container">
              <div
                class="link-container"
                *ngFor="let item of filterBurgerMenuOptions(row.itemId)"
                (click)="burgerMenuClicked(item.eventName, row.itemId)"
              >
                <p class="burger-link">
                  {{ item.content }}
                </p>
              </div>
            </div>
          </mat-menu>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div
  *ngIf="
    (tableData?.displayNoDataMessage === undefined ||
      tableData?.displayNoDataMessage) &&
    tableData.data &&
    tableData.data.length === 0 &&
    !isLoading
  "
>
  <div class="no-data">
    <mat-icon fontSet="material-icons-round" class="warning-icon"
      >warning</mat-icon
    >
    <span class="kbody-large strong">There's nothing here.</span>
    <span class="kbody-large">{{ tableData.noDataFoundMessage }}</span>
    <app-button
      class="no-data-button"
      [class]="'secondary'"
      *ngIf="tableData.noDataButtonText"
      [text]="tableData.noDataButtonText"
      (click)="noDataButtonClicked()"
    ></app-button>
  </div>
</div>

<div
  class="selection-summary"
  *ngIf="tableData.selectable && selection.length > 0"
>
  <div
    id="summary-panel"
    class="summary-panel"
    *ngIf="summaryPanelOpen"
    (mouseleave)="closeSummaryPanel()"
    (click)="stopBubble($event); panelInFocus = true"
  >
    <table>
      <thead>
        <th
          class="selectable-column"
          *ngIf="tableData.selectable"
          (click)="toggleAllPanelSelection()"
        >
          <mat-checkbox
            color="#005965"
            [checked]="checkAllPanelSelected() === true"
            [indeterminate]="checkAllPanelSelected() === 'indeterminate'"
          ></mat-checkbox>
        </th>
        <th>
          ({{ selection.length - panelUnSelected.length }})
          {{
            selection.length - panelUnSelected.length === 1 ? "Item " : "Items "
          }}
          selected
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let item of selection">
          <td (click)="togglePanelCell($event, item.itemId)">
            <mat-checkbox
              color="#005965"
              [checked]="checkIfPanelSelected(item.itemId)"
            >
            </mat-checkbox>
          </td>
          <td>
            <app-table-cell [cell]="item.columnData[0]" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="quick-summary">
    <div class="collapsed" *ngIf="!summaryOpen">
      <app-new-button
        leftIcon="horizontal_rule"
        customPadding="16px"
        size="md"
        fontSize="16px"
        (focus)="summaryOpen = true"
        (mouseenter)="summaryOpen = true"
      ></app-new-button>
    </div>
    <div class="open" *ngIf="summaryOpen">
      <app-new-button
        leftIcon="horizontal_rule"
        customPadding="14px 16px"
        size="md"
        fontSize="16px"
        (clickHandler)="toggleSummaryPanel()"
        (mouseleave)="checkIfSummaryClose()"
        >({{ selection.length - panelUnSelected.length }})
        {{
          selection.length - panelUnSelected.length === 1
            ? "Selection"
            : "Selections"
        }}</app-new-button
      >
    </div>
  </div>
</div>
<div class="transparent"></div>
<app-pagination
  class="pagination"
  [useOldTableStyling]="false"
  *ngIf="
    tableData?.meta &&
    tableData?.meta?.usePagination &&
    tableData.meta?.totalItems !== 0
  "
  [tableData]="tableData.meta"
  [selectedItemsPerPage]="tableData?.meta?.itemsPerPage"
  [showActiveItems]="showActiveItems"
  (pageChanged)="pageChanged.emit($event)"
  (itemsPerPageChange)="itemsPerPageChange.emit($event)"
></app-pagination>

<div class="loading-spinner" *ngIf="isLoading">
  <app-loading-spinner class="spinner" />
</div>
