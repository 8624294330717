import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { PushModalComponent } from './components/push-modal/push-modal.component';
import { RegistrationRequirementModalComponent } from '../registration-requirement-modal/registration-requirement-modal.component';
import { RegistrationWaitlistModalComponent } from '../registration-waitlist-modal/registration-waitlist-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { UpdateCourseComponent } from '../update-course/update-course.component';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CoursesService } from 'src/app/services/courses.service';
import Course from '../update-course/course.class';

@Component({
  selector: 'app-catalog-course-details',
  templateUrl: './catalog-course-details.component.html',
  styleUrls: [ './catalog-course-details.component.scss' ],
})

export class CatalogCourseDetailsComponent implements OnInit, OnDestroy {
  course: Course;
  actions = [
    // { text: 'Duplicate', icon: 'content_copy' },
    // { text: 'Email', icon: 'email' },
    // { text: 'Validate', icon: 'checklist' },
    // { text: 'Reports', icon: 'document_scanner' },
    { text: 'Push', icon: 'send' },
    // { text: 'Print', icon: 'print' },
    { text: 'Edit', icon: 'edit' },
  ]
  completeCourseSessionDetails: any;
  isLoading = false;
  permissions: any;
  permissionsSubscrioption: any;

  courseItemId: number;

  canRegister = false;
  canWaitList = false;


  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<CatalogCourseDetailsComponent>,
    public dialog: MatDialog,
    private coursesService: CoursesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  // TODO: once requirements has it's group update, the requirements will need an update
  ngOnInit(): void {
    this.coursesService.clearCourseSessionData();

    this.permissionsSubscrioption = this.authService.permissions$.subscribe((permissions: any) => {
      this.permissions = permissions;
      // these remove actions based on permissions
      if(!this.permissions?.COURSE_ADD) {
        this.actions = this.actions.filter((action) => action.text !== 'Duplicate');
      }
      if(!this.permissions?.COURSE_EDIT) {
        this.actions = this.actions.filter((action) => action.text !== 'Edit');
      }
      if(!this.permissions?.VALIDATE_ATTENDANCE) {
        this.actions = this.actions.filter((action) => action.text !== 'Validate');
      }
      if(!this.permissions?.COURSE_PUSH) {
        this.actions = this.actions.filter((action) => action.text !== 'Push');
      }
    });


    const courseItemId = this.data?.courseItemId || this.data.selectedCourse?.courseItemId;
    this.courseItemId = courseItemId;

    this.isLoading = true;
    this.coursesService.getCourseSession(courseItemId, { includedAssociations: 'AllCourseDetails' });
    this.coursesService.courseSessionData$.subscribe((courseSessionData: any) => {
      if (!courseSessionData) return;
      this.completeCourseSessionDetails = courseSessionData;
      this.course = new Course(courseSessionData);
      this.isLoading = false;
      this.parseCourse();
    });
  }

  // ngOnChanges() { }

  parseCourse() {
    const registeredCount = Number(this.course.registeredCount) || 0;
    const courseSize = Number(this.course.courseSize) || 0;
    const allowWaitListFlag = this.course.allowWaitListFlag || false;

    this.canRegister = registeredCount < courseSize;
    this.canWaitList =  !!(registeredCount >= courseSize && allowWaitListFlag);
  }

  takeAction = (action: any) => {
    // here we disable the buttons while the session data is loading
    if(this.isLoading) return;
    // TODO: add functionality to the button list
    switch (action.text) {
    // case 'Duplicate':
    //   this.duplicateCourse();
    //   break;
    // case 'Email':
    //   this.emailCourse();
    //   break;
    // case 'Validate':
    //   this.validateCourse();
    //   break;
    // case 'Reports':
    //   this.reportsCourse();
    //   break;
    case 'Push':
      this.openPushModal();
      break;
    // case 'Print':
    //   this.printCourse();
    //   break;
    case 'Edit':
      this.openEditCourseModal();
      break;
    default:
      break;
    }
  }

  cancelCourse = () => {
    const confirmDialogRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert',
        title: 'Are you sure you want to cancel this session?',
        text: 'Users will be unregistered and the session will be removed. This action cannot be undone.',
        confirmButtonTitle: 'Yes, cancel course',
        cancelButtonTitle: 'No, go back',
      },
    })
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'verified') {
        this.coursesService.cancelCourseSession(this.course.courseItemId, this.dialogRef);
      }
    })
  }

  openEditCourseModal = () => {
    const course = JSON.parse(JSON.stringify(this.course));
    // TODO - NOW
    const dialog = this.dialog.open(UpdateCourseComponent, { data: course, disableClose: true })
    dialog.afterClosed().subscribe((result: any) => {
      if (result?.statusCode === 1000) {
        this.dialogRef.close('reload');
      } else {
        this.dialogRef.close();
      }
    });
  }

  openPushModal = () => {
    this.dialog.open(PushModalComponent, { data: { completeCourseSessionDetails: this.completeCourseSessionDetails } });
  }

  openRegistrationModal = () => {
    this.dialog.open(RegistrationRequirementModalComponent, { data: { selectedCourse: this.data.selectedCourse, userRequirements: this.data.userRequirements } })
  }

  openWaitlistModal = () => {
    this.dialog.open(RegistrationWaitlistModalComponent, { data: { selectedCourse: this.data.selectedCourse } })
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.course = null;
    this.permissionsSubscrioption.unsubscribe();
  }

}
