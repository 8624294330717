import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WaitlistsService {
  private _waitlistsData$ = new BehaviorSubject<any>(null);
  public readonly waitlistsData$ = this._waitlistsData$.asObservable();
  private _waitlistsConflictsData$ = new BehaviorSubject<any>(null);
  public readonly waitlistsConflictsData$ = this._waitlistsConflictsData$.asObservable();
  _user$: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getWaitlists = (additionalParams?: any) => {
    this._waitlistsData$.next(null);
    const params = {
      'loginName': this._user$.loginName,
      'limit': 25,
      ...additionalParams,
    }
    return this.http.get(`${environment.expressUrl}/courses/waitlisted`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: params,
      })
      .subscribe({
        next: (response: any) => {
          if (!response.data) {
            return this._waitlistsData$.next(null);
          }
          this._waitlistsData$.next(response.data);
        },
        error: (error: Error) => {
          this._waitlistsData$.next(error);
        },
      })
  }

  getWaitlist = (id: number) => {
    return this.http.get(`${environment.expressUrl}/courses/waitlisted/${id}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName },
      })
      .subscribe({
        next: (response: any) => {
          this._waitlistsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getWaitlistConflicts = (waitlistId: number, userId: number) => {
    return this.http.get(`${environment.expressUrl}/courses/waitlisted/${waitlistId}/user/${userId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName },
      })
      .subscribe({
        next: (response: any) => {
          this._waitlistsConflictsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  moveUserToTop = (selectedUser) => {
    return this.http.put(
      `${environment.expressUrl}/courses/waitlisted/${selectedUser.courseItemId}/reorder-waitlist`,
      {
        'loginName': this._user$.loginName,
        'courseId': selectedUser.courseId,
        'waitlistAdjustments': [
          {
            'registrationId': selectedUser.registrationId,
            'waitlistOrder': 1,
          },
        ],
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
