<div class="container" [classList]="[size]">
  <ng-container *ngIf="imageSrc">
    <img class="image" [src]="imageSrc" alt="Profile Picture" />
  </ng-container>

  <div
    class="placeholder"
    *ngIf="!imageSrc"
    [ngStyle]="{ 'background-color': randomColor }"
  >
    <span *ngFor="let l of getInitials()">{{ l }}</span>
  </div>
</div>
