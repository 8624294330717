<div class="modal">
  <ng-container>
    <div id="header">
      <div class="header-row">
        <div class="title">
          <mat-icon fontSet="material-icons-round" class="close"
            >list_alt</mat-icon
          >
          <span class="kbody-huge"
            >Course Details: Course ID {{ courseItemId }}</span
          >
        </div>
        <mat-icon
          fontSet="material-icons-round"
          class="close"
          (click)="closeModal()"
          >close</mat-icon
        >
      </div>
      <div class="admin-button-row">
        <div
          class="btn"
          *ngFor="let action of actions"
          (click)="takeAction(action)"
        >
          <mat-icon fontSet="material-icons-round" class="icon">{{
            action.icon
          }}</mat-icon>
          <span class="kbody-medium">{{ action.text }}</span>
        </div>
      </div>
    </div>

    <div class="content" *ngIf="!isLoading && course">
      <app-finish
        (isLoadingStatus)="isLoading = $event"
        [isCourseDetails]="true"
        [course]="course"
        (completeCourseSessionDetails)="
          this.completeCourseSessionDetails = $event
        "
      ></app-finish>
      <!-- TODO: Update course details with cancel functionality -->
    </div>

    <div id="footer" class="buttons">
      <app-button
        id="cancel"
        *ngIf="permissions.COURSE_ADD && course"
        text="Cancel Course"
        [class]="'red'"
        (click)="cancelCourse()"
      ></app-button>
      <div class="actions">
        <app-button
          (click)="closeModal()"
          text="Exit Window"
          [class]="'white'"
        ></app-button>
        <app-button
          *ngIf="canRegister && !data.noRegistrationButtons"
          text="Register for this course"
          [icon]="{ materialIcon: 'playlist_add_check' }"
          (click)="openRegistrationModal()"
        ></app-button>
        <app-button
          *ngIf="canWaitList && !data.noRegistrationButtons"
          text="Join Waitlist"
          [icon]="{ materialIcon: 'access_time' }"
          [class]="'orange'"
          (click)="openWaitlistModal()"
        ></app-button>
      </div>
    </div>
  </ng-container>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
</div>
