<app-content-container title="Waitlists">
  <div class="header-container">
    <h1 class="title">Selected Waitlisted Course</h1>
    <app-search-filter
      id="search"
      [value]="searchQuery"
      placeholder="Search"
      (valueChange)="searchQueryChanged($event)"
    />
  </div>
  <app-new-table
    (rowClickedEvent)="handleRowClicked($event)"
    (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
    [tableData]="tableData"
  />
</app-content-container>
