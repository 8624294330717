import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { CourseFilter } from '../../courseFilter';

@Component({
  selector: 'app-general-course-filters',
  templateUrl: './general-course-filters.component.html',
  styleUrls: [ './general-course-filters.component.scss' ],
})
export class GeneralCourseFiltersComponent implements OnInit {
  @Input() mainFilter: CourseFilter;
  @Input() hasPermission = false;
  @Output() updateMainFilter = new EventEmitter<object>();


  privilegedFilters: { text: string, parameter: keyof CourseFilter }[] = [ { text: 'Ignore the registration window', parameter: 'ignoreRegistrationWindowFlag' } ]

  basicList: {text: string, parameter: keyof CourseFilter}[] = [
    { text: 'Show cancelled courses', parameter: 'showCancelledCoursesFlag' },
    // { text: 'Show previous hours', parameter: 'showPreviousHoursFlag' },
    { text: 'Show async', parameter: 'showAsyncFlag' },
    { text: 'Show anytime courses', parameter: 'showAnytimeCoursesFlag' },
    { text: 'Show virtual', parameter: 'showVirtualFlag' },
    { text: 'Show outside courses', parameter: 'showOutsideCoursesFlag' },
    { text: 'Show waitlisted courses', parameter: 'showWaitlistedCoursesFlag' },
  ]

  checkboxList: { text: string, parameter: keyof CourseFilter }[] = [];


  ngOnInit(): void {
    if (this.hasPermission) {
      this.hasPermission = true;
      this.checkboxList = [ ...this.basicList, ...this.privilegedFilters ]
    } else {
      this.hasPermission = false;
      this.checkboxList = [ ...this.basicList ]
    }
  }

  updateCheckboxFilter(parameter: keyof CourseFilter, event: any): void {
    (this.mainFilter[parameter] as boolean) = event.target.checked;
  }

  updateFilter(parameter: keyof CourseFilter, event: any): void {
    (this.mainFilter[parameter] as string)= event.target.value as string;
  }

  resetGeneralFilters(): void {
    this.mainFilter.resetGeneral(this.hasPermission);
    const otherFilters = [
      'startDate', 'endDate', 'hours',
    ];
    otherFilters.forEach((filter) => {
      this.updateMainFilter.emit({ parameter: filter, value: '' });
    });
  }
}
