import {
  Component, HostBinding, Input,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
})
export class AvatarComponent {
  user$: Observable<any>;
  entity$: Observable<any>;

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() email = '';
  @Input() imageSrc ='';
  // imageSrc = 'https://s3-alpha-sig.figma.com/img/4c55/bbb7/b275d77a819277ff888969cac47e1c2b?Expires=1739750400&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=GswN9zfnQZdKHgBFCtudtGmTFzhRYM8BeOf1fB5mz56A-6dLjffHi10ADsEhNCellhFCATlZBczgsxbmHwAjIq-z~-bkoAUoIq9sj6wj0UEQiB7Ozf5~-WGSeyBkLsDJPBC-uyTenU~R6DFIM43V~0wB~eu25bbLTtji~boKKcsNAy0lkhrYjwIiEJAvxPw2eIn6H31wHCrUq7g61fhCMnlhSsWRHfhZUXsifXsbOfDLUAy6CvOHzc3kEDfalepzK4cGLATwoav-biCJ7HNr~Lw9k7HXnu8ILR8cMwEmFpLAK0OB9odwe~hJizcn6XUxJCRu-tUQU6nyQ9Yh90TNvA__'
  @Input() inputInitials: string[];
  initials: string[] = [ 'A', 'B' ];

  @HostBinding('class.with-image')
  get withImageClass(): boolean {
    return !!this.imageSrc;
  }

  // Define the possible placeholder colors
  private readonly colors = [
    '#2E3B63', // default blue
    '#007D87', // Aqua
    '#A87C36', // Yellow
    '#E25B34', // Orange
    '#BC2B2B', // Red
    '#779F4E', // Red
  ];

  randomColor: string;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if ((!this.firstName || !this.lastName)) {
      this.user$ = this.authService.user$;
      this.entity$ = this.authService.entity$;

      this.user$.subscribe((user: any) => {
        if (!user) return;
        if (!this.firstName) {
          this.firstName = user.firstName;
        }
        if (!this.lastName) {
          this.lastName = user.lastName;
        }
        if (!this.email) {
          this.email = user.contactEmail ?? '';
        }
      });
    }
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    this.randomColor = this.colors[randomIndex];
  }

  getInitials(): string[] {
    if ( this.inputInitials ) {
      return this.inputInitials;
    }
    return [ this.firstName.charAt(0).toLocaleUpperCase(), this.lastName.charAt(0).toLocaleUpperCase() ];

  }
}
