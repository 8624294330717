import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class RequirementsService {
  private _requirementsData$ = new BehaviorSubject<any>(null);
  public readonly requirementsData$ = this._requirementsData$.asObservable();

  _user$: any;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  getPagination = (pageDirection: any) => {
    let links = '';
    this.requirementsData$.subscribe(data =>
      links = data.links)
    if (links[pageDirection] === '') {
      return;
    }
    return this.http.get(`${environment.expressUrl}/maintenance/requirements/` + links[pageDirection],
      { headers: { 'Content-Type': 'application/vnd.api+json' } })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
          console.log('response', response);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }


  getRequirements = (
    requirementName?: string,
    includedNodeTypes = 'SINGLE,GROUP_PARENT',
    activeFlag = 1,
    sortColumn = 'requirement',
    sortDirection: 'ASC' | 'DESC' = 'ASC',
    limit?: number,
    offset = 0,
  ) => {
    const params: {
      loginName: string,
      requirementNameFilter?: string,
      activeFlag: number,

      includedNodeTypes: string,
      includedAssociations?: string,

      limit?: number,
      offset?: number,

      sortColumn?: string,
      sortDirection?: 'ASC' | 'DESC',
    } = {
      loginName: this._user$.loginName,
      activeFlag: activeFlag,
      includedAssociations: 'Requirement',
      includedNodeTypes: includedNodeTypes || 'SINGLE,GROUP_PARENT',
    }

    if (requirementName) {
      params.requirementNameFilter = requirementName;
    }

    if (limit) {
      params.limit = limit;
      params.offset = offset;
    }

    if (sortColumn) {
      params.sortColumn = sortColumn;
      params.sortDirection = sortDirection || 'ASC';
    }

    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params,
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getRequirementsAll = () => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'sortDirection': 'DESC', 'sortColumn': 'topMostParentId', 'activeFlag': 1,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getRequirementsChildrenIncluded = () => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'activeFlag': 1, 'sortDirection': 'DESC', 'sortColumn': 'topMostParentId', 'includedAssociations': 'AllChildrenDetails', 'includedNodeTypes': 'SINGLE,GROUP_PARENT',
        },
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getRequirementDetails = (requirementId: number, includedAssociations: string) => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements/${requirementId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this._user$.loginName, 'includedAssociations': includedAssociations },
      })
  }

  getSortedRequirements = (
    sortColumn: string, sortDirection: string, activeFlag: boolean | 1 | 0, limit = 25, offset = 0,
  ) => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'limit': limit,
          'offset': offset,
          'sortColumn': sortColumn,
          'sortDirection': sortDirection,
          'activeFlag': activeFlag,
          'includedNodeTypes': 'SINGLE,GROUP_PARENT',
          'includedAssociations': 'Requirement',
        },
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  getRequirementsFilteredByName = (requirementFilterString: string, activeFlag: boolean | 1 | 0) => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName,
          'requirementNameFilter': requirementFilterString,
          'activeFlag': activeFlag,
          'limit': 25,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          this._requirementsData$.next(error);
          console.log('error', error);
        },
      })
  }

  getUserRequirements = (userId: number, arrangeByGroup: boolean) => {
    return this.http.get(`${environment.expressUrl}/maintenance/user-requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          loginName: this._user$.loginName,
          userId,
          arrangeByGroups: arrangeByGroup ? 1 : 0,
        },
      })
  }

  getUserFullRequirements = (userId: number, arrangeByGroup: boolean) => {
    return this.http.get(`${environment.expressUrl}/maintenance/user-requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          loginName: this._user$.loginName,
          userId,
          // includedNodeTypes: "SINGLE,GROUP_PARENT",
          arrangeByGroups: 1,
        },
      })
  }

  getInactiveRequirements = (limit?: any, offset?: any) => {
    return this.http.get(`${environment.expressUrl}/maintenance/requirements`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          'loginName': this._user$.loginName, 'limit': limit || 25, 'offset': offset || 0, 'activeFlag': 0, 'includedNodeTypes': 'SINGLE,GROUP_PARENT', 'includedAssociations': 'Requirement',
        },
      })
      .subscribe({
        next: (response: any) => {
          this._requirementsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  addRequirement = (requirement: any) => {

    const ChildArray = requirement.requirements;
    let requirementsArray: any = [];
    for (const child of ChildArray) {
      const newRow = {
        'id': child.id,
        'requirement': child.requirement,
        'displayOrder': 1, // this has no input in the UI
        'level': child.level,
        'parentRequirementName': child.parentRequirementName, // name of parent/node to current requirement level
        'description': child.description,
        'header': child.header, // this has no input in the UI
        'trackingUom': child.trackingUom, //Default only currently
        'trackingValue': parseFloat(child.trackingValue),

        'requirementType': child.requirementType ? 'Counselor' : null, //  should be requirement.counselorFlag ? 'Counselor' : null, but is currently failing when is 'Counselor'
        'rollingFlag': child.rollingFlag ? 1 : 0,
        'rollingYears': parseFloat(child.rollingYears),
        'reportToStateFlag': child.reportToStateFlag ? 1 : 0, // REP reporting in UI
        'allUsersFlag': child.allUsersFlag ? 1 : 0, // makes it default for all users
        'allCoursesFlag': child.allCoursesFlag ? 1 : 0, // makes it default for all courses
      }
      requirementsArray = [ ...requirementsArray, newRow ];
    }

    return this.http.post(
      `${environment.expressUrl}/maintenance/requirements`,
      {
        'loginName': this._user$.loginName,
        'type': requirement.type, //single/group
        'requirements': requirementsArray,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  toggleRequirementActivation = (requirement: any, isActivating: boolean) => {
    const activateEndpoint = isActivating ? 'activate' : 'deactivate';
    return this.http.put(
      `${environment.expressUrl}/maintenance/requirements/${requirement.Requirement.id}/${activateEndpoint}`,
      { 'loginName': this._user$.loginName },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  updateRequirement = (requirement: any) => {

    const ChildArray = requirement.requirements;
    let requirementsArray: any = [];
    for (const child of ChildArray) {
      const newRow = {
        'id': child.id,
        'displayOrder': 1, // this has no input in the UI
        'parentRequirementName': child.parentRequirementName, // name of parent/node to current requirement level
        'header': child.header, // this has no input in the UI

        // CAN UPDATE THE FOLLOWING IF THERE IS HISTORICAL DATA, CURRENTLY JUST TURNED OFF FOR UPDATING AT ALL
        'requirementType': child.requirementType ? 'Counselor' : null, //  should be requirement.counselorFlag ? 'Counselor' : null, but is currently failing when is 'Counselor'
        'requirement': child.requirement,
        'reportToStateFlag': child.reportToStateFlag ? 1 : 0, // REP reporting in UI
        'trackingValue': parseFloat(child.trackingValue),
        'description': child.description,

        // CANNOT UPDATE THE FOLLOWING
        //'trackingUom': child.trackingUom, //Default only currently
        //'rollingFlag': child.rollingFlag ? 1 : 0,
        //'rollingYears': parseFloat(child.rollingYears),
        //'allUsersFlag': child.allUsersFlag ? 1 : 0, // makes it default for all users
        //'allCoursesFlag': child.allCoursesFlag ? 1 : 0, // makes it default for all courses
        //'level': child.level,
      }
      requirementsArray = [ ...requirementsArray, newRow ];
    }
    return this.http.put(
      `${environment.expressUrl}/maintenance/requirements/`,
      {
        'loginName': this._user$.loginName,
        'type': requirement.type, //single/group
        'requirements': requirementsArray,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
