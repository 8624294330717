<div class="catalog-container">
  <!-- <app-breadcrumbs /> -->

  <div class="title-row">
    <h1>Course Catalog</h1>
  </div>

  <div class="header-row">
    <app-search-filter
      id="search-filter"
      customHeight="27px"
      (valueChange)="searchCoursesByString($event)"
    />
    <app-select
      id="year-select"
      *ngIf="hasPrivilege && formattedYears?.length > 1"
      [value]="selectedYear"
      [options]="formattedYears"
      placeholder="Select Year"
      styleType="outline"
      (selectedValue)="updateYearFilter($event)"
    />
    <app-new-button
      id="filter-button"
      color="tonal"
      size="sm"
      iconStyle="outlined"
      customPadding="12px 18px"
      leftIcon="sort"
      customFontSize="14px"
      (clickHandler)="openFiltersModal()"
    >
      Apply Filters
    </app-new-button>
    <app-new-button
      id="add-new-button"
      color="green"
      size="sm"
      leftIcon="add"
      customPadding="12px 18px"
      customFontSize="14px"
      (clickHandler)="popAddNewModal()"
      >Add Course
    </app-new-button>
  </div>
  <div id="table">
    <app-new-table
      [tableData]="tableData"
      [isLoading]="isLoading"
      (burgerMenuClickedEvent)="onBurgerMenuClicked($event)"
      (rowClickedEvent)="onRowClicked($event)"
      (pageChanged)="onPageChanged($event)"
      (sortClickedEvent)="onSortClicked($event)"
      (itemsPerPageChange)="onItemsPerPageChange($event)"
    ></app-new-table>
  </div>
</div>
