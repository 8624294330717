const asDate = (date: Date | string) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString();
}

const asTime = (date: Date | string) => {
  const dateObj = new Date(date);

  // time will be formatted as 12-hour time with AM/PM without seconds

  return dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

const asDateTime = (date: Date | string) => {
  // date will be formatted as MM/DD/YYYY
  // time will be formatted as 12-hour time with AM/PM without seconds
  return `${asDate(date)} ${asTime(date)}`;
}


export const formatDate = {
  asDate,
  asTime,
  asDateTime,
};
