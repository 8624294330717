import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { GeneralDataCell, StatusDataCell  } from 'src/app/components/new-table/table-cell/table-cell.component';
import { Observable, Subscription } from 'rxjs';
import { WaitlistsService } from 'src/app/services/waitlists.service';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogCourseDetailsComponent } from 'src/app/routes/manage-courses/components/course-catalog/components/catalog-course-details/catalog-course-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: [ './waitlist.component.scss' ],
})
export class WaitlistComponent implements OnInit, OnDestroy {
  isLoading = false
  tableData: NewTableData = {
    columnTitles: [
      {
        name: 'Course',
        sortable: true,
        sortByProp: 'courseName',
      },
      {
        name: 'Date/Time',
        sortable: true,
        isDefaultSort: true,
        sortDirection: 'ASC',
        sortByProp: 'dateOffered',
      },
      {
        name: 'Hours',
        sortable: false,
        sortByProp: 'takenCount',
      },
      {
        name: 'Status',
        sortable: false,
        sortByProp: 'status',
      },
    ],
    selectable: false,
    data: [],
    noDataFoundMessage: 'No courses found',
    rowStyling: 'default',
    title: 'Conflicts',
    burgerContent: [
      {
        content: 'View Course Details',
        eventName: 'view-details',
      },
    ],
    hasBurgerMenu: true,
    meta: {
      totalItems: 0,
      itemsPerPage: 0,
      totalPages: 0,
      curPage: 0,
      usePagination: true,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
  }
  sortColumn = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortByProp || '';
  sortDirection = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortDirection || 'ASC';
  offset = 0;
  itemsPerPage = 25;
  usersWaiting: any[] = [];
  waitlist;
  conflicts;
  selectedUser;
  formattedConflicts: NewTableData['data']= [];
  reorderSubscription: Subscription;
  courseRegistrationSubscription: any;
  waitlistsData$ = this.waitlistsService.waitlistsData$;
  waitlistsSubscription = this.waitlistsData$.subscribe((data) => {
    if (!data) {
      this.isLoading = false
      return;
    }

    this.waitlist = data
    this.isLoading = false
    if (data?.WaitlistOrder) {
      this.selectUser(data?.WaitlistOrder[0])
    }
  });
  waitlistsConflictsData$ = this.waitlistsService.waitlistsConflictsData$;
  waitlistsConflictsSubscription = this.waitlistsConflictsData$.subscribe((data) => {
    if (!data) {
      this.isLoading = false
      return;
    }

    this.conflicts = data
    this.formatWaitlistConflicts(this.conflicts);
    this.tableData.data = this.formattedConflicts;
    this.isLoading = false
  });

  constructor(
    private waitlistsService: WaitlistsService,
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
    private toastService: ToastService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.searchForWaitlist()
  }

  handleBurgerMenuClicked = (event) => {
    if (event.eventName === 'view-details') {
      this.openCourseDetailsModal(event.itemId)
    }
  }

  openCourseDetailsModal = (id) => {
    this.dialog.open(CatalogCourseDetailsComponent, { data: { courseItemId: id, userRequirements: [] } })
  }

  searchForWaitlistConflicts = (userId) => {
    this.isLoading = true
    this.waitlistsService.getWaitlistConflicts(this?.route?.snapshot?.params['id'],
      userId)
  }

  searchForWaitlist() {
    this.isLoading = true
    this.waitlistsService.getWaitlist(this?.route?.snapshot?.params['id'])
  }

  goBack = () => {
    this.router.navigate([ '/manage-courses/waitlists' ])
  }

  moveToTop = () => {
    this.isLoading = true
    this.reorderSubscription = this.waitlistsService.moveUserToTop(this.selectedUser).subscribe({
      next: (response: any) => {
        this.searchForWaitlist()
      },
      error: (error: any) => {
        this.isLoading = false
        console.error(error)
      },
    })
  }

  cancelWaitlistRequest = () => {
    const statusKid = 'REMOVED'
    const updateStatusData = { id: this.selectedUser.registrationId, statusKid: statusKid };
    this.courseRegistrationSubscription = this.coursesService.updateCourseRegistration(updateStatusData).subscribe({
      next: (response: any) => {
        this.toastService.setToast({ text: 'Waitlist Request Removed.', type: 'success' })
        this.searchForWaitlist()
      },
      error: (error: any) => {
        this.toastService.setToast({ text: 'Error removing Waitlist Request.', type: 'error' })
      },
    });
  }

  getDateAndTime = () => {
    const date = this.formatDateOffered(this.waitlist?.dateOffered)
    const time = this.formatSessionTime(this.waitlist?.sessionStartDate, this.waitlist?.sessionEndDate)
    return `${date}, ${time}`
  }

  selectUser = (user) => {
    this.selectedUser = user
    this.isLoading = true
    this.searchForWaitlistConflicts(user.userId)
  }

  formatDateOffered = (date) => {
    const dt = new Date(date)
    const month = dt.getMonth() + 1
    const day = dt.getDate()
    const year = dt.getFullYear().toString().slice(2, 4)
    return `${month}/${day}/${year}`
  }

  formatSessionTime = (startDate, endDate) => {
    const startDt = new Date(startDate)
    let hours = startDt.getHours();
    let minutes = startDt.getMinutes().toString();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes.padStart(2, '0')
    const strTime = hours + ':' + minutes + ' ' + ampm;
    const endDt = new Date(endDate)
    hours = endDt.getHours();
    minutes = String(endDt.getMinutes());
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes.padStart(2, '0')
    const endTime = hours + ':' + minutes + ' ' + ampm;
    return `${strTime} - ${endTime}`
  }

  formatWaitlistConflicts(conflicts: any[]): typeof this.formattedConflicts {
    this.formattedConflicts = conflicts?.map(conflict => {
      const conflictData = {
        name: conflict.courseName,
        id: conflict.courseItemId,
        takenCount: conflict.targetValue,
        status: conflict.status,
        dateOffered: this.formatDateOffered(conflict.dateOffered),
        sessionTime: this.formatSessionTime(conflict.sessionStartDate, conflict.sessionEndDate),
      }

      const nameCell: GeneralDataCell = {
        type: 'general',
        mainText: conflictData.name,
        subText: conflictData.id,
        mainFontStyle: 'bold',
      }

      const dateCell: GeneralDataCell = {
        type: 'general',
        mainText: conflictData.dateOffered,
        subText: conflictData.sessionTime,
        mainFontStyle: 'bold',
      }

      const hoursCell: GeneralDataCell = {
        type: 'general',
        mainText: `${Number(conflictData.takenCount).toFixed(2)}`,
        mainFontStyle: 'normal',
      }

      const statusCell: StatusDataCell = {
        type: 'status',
        statusDisplay: conflictData.status,
        statusKid: conflictData.status,
        statusType: 'active',
      }

      return {
        itemId: conflict.courseItemId,
        columnData: [
          nameCell, dateCell, hoursCell, statusCell,
        ],
      }
    });

    return this.formattedConflicts;
  }

  ngOnDestroy() {
    this.waitlistsSubscription.unsubscribe();
    this.waitlistsConflictsSubscription.unsubscribe();
  }

}
