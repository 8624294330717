import { Component, Input } from '@angular/core';
import { UpdateMyCourseRequestComponent } from '../my-course-requests/components/update-my-course-request/update-my-course-request.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-my-pd-plan',
  templateUrl: './my-pd-plan.component.html',
  styleUrls: [ './my-pd-plan.component.scss' ],
})
export class MyPdPlanComponent {
  @Input() user: any;
  navTitles = [
    { text: 'My Courses', icon: 'list' },
    // { text: 'Tag Details', icon: 'label' },
    // { text: 'Requirement Summary', icon: 'summarize' },
    // { text: 'Survey Responses', icon: 'poll' },
  ]

  myCoursesTabs = [
    { text: 'Course List View', value: 'courses' },
    { text: 'Requirements View', value: 'requirements' },
  ]

  selectedTab = this.navTitles[0];
  selectedCoursesTab = this.myCoursesTabs[0].value;
  courses: any;

  constructor(public dialog: MatDialog, private toast: ToastService) { }

  changeTab = (tab: any) => {
    this.selectedTab = tab;
  }

  changeCoursesTab = (event: Event) => {
    const tab = event.target as HTMLSelectElement;
    this.selectedCoursesTab = tab.value;
  }

  toggleCreateModal = () => {
    this.dialog.open(UpdateMyCourseRequestComponent).afterClosed().subscribe((result) => {
      if (result?.statusCode === 1000) {
        this.toast.setToast({
          text: 'Course request submitted successfully',
          type: 'success',
          dismissible: false,
          icon: true,
        })
      }
    })
  }
}
