<div class="container">
  <div class="fixed-area">
    <div class="title-row">
      <span class="klabel-huge">Filter by Course Type</span>
      <app-button
        [text]="'De-select all'"
        class="grey"
        (click)="clearCourseTypesFilter()"
      ></app-button>
    </div>
  </div>
  <div class="content">
    <div class="checkboxes">
      <div *ngIf="courseTypeList.length !== 0" class="checkbox-row">
        <input
          type="checkbox"
          class="kinput"
          [checked]="
            courseTypeList?.length === mainFilter.courseTypes.length &&
            courseTypeList.length !== 0
          "
          (change)="selectAllCourseTypesFilter($event)"
        />
        <span class="kbody-medium">Select all course types</span>
      </div>
      <div class="checkbox-row" *ngFor="let courseType of courseTypeList">
        <input
          type="checkbox"
          class="kinput"
          [checked]="checkIncludesRequirement(courseType)"
          (change)="updateCheckboxFilter($event, courseType)"
        />
        <span class="kbody-medium">{{ courseType?.courseType }}</span>
      </div>
    </div>
  </div>
</div>
